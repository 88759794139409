import React, { useState } from 'react'
import { Link } from "gatsby"
import { generateImageData, StaticImage } from "gatsby-plugin-image"
import Grid from "../../elements/grid"
import { SizedText, Body} from '../../elements/typography'
import ExternalLink from '../../elements/externalLink'
import Icon from "../../elements/icon"

import fbIcon from "../../../theme/icons/facebook.svg"
import igIcon from "../../../theme/icons/instagram.svg"
import liIcon from "../../../theme/icons/linkedIn.svg"
import ytIcon from "../../../theme/icons/youtube.svg"
import twIcon from "../../../theme/icons/twitter.svg"

import locationIcon from "../../../theme/icons/location.svg"
import emailIcon from "../../../theme/icons/email.svg"
import phoneIcon from "../../../theme/icons/phone-dark.svg"
import faxIcon from "../../../theme/icons/fax.svg"
import PhExternalLink from '../../elements/externalLink'


const MenuSection = ({ data, styles }) => {
  const [isOpen, setIsOpen] = useState(false);

  const links = data.subMenu.map(i => (
    <li key={`ft-${i.path}`}>
      <Link 
        to={i.path} 
        css={{
          display: `block`, 
          padding: `0.25rem 0`, 
          color: `#fff`, 
          textDecoration: `none`, 
          ':link': {color: `#fff`},
          ':visited': {color: `#fff`},
          ':hover': {textDecoration: `underline`}
        }}
        activeStyle={{color: `#3498db`}}
        partiallyActive={true}
      >{i.longText}</Link>
    </li>
  ))

  return (
    <>
      <div css={{
        borderTop: `2px solid #3498db`,
        padding: `0.5rem 0 0.5rem 0.5rem`,
        '@media (min-width: 1281px)': {
          borderTop: `none`,
          padding: `0`,
        },
        ...styles
      }}>
        <div css={{ 
          display: `flex`, 
          justifyContent: 'space-between'
        }}>
          <SizedText as="h5" smallSize="1" largeSize="1.125">
            <Link to={data.path} css={{textDecoration: `none`, color: `#fff !important`}}>{data.longText}</Link>
          </SizedText>
          <SizedText as="h5" smallSize="1" largeSize="1.125" styleOverrides={{ 
            padding:`0 0.5rem`,
            transform: isOpen ? `rotate(45deg)` : `rotate(0deg)`,
            '@media (min-width: 1281px)': {display: `none`}
            }} onClick={() => setIsOpen(!isOpen)}>
              <span>+</span>
          </SizedText>
        </div>
        <hr css={{width: `50px`, border: `1px solid #3498db`, display: `none`, '@media (min-width: 1281px)': { display: `inline-block`}}} />
      </div>
      <div css={{display: isOpen ? `block` : `none`, 
      '@media (min-width: 1281px)': { display: `block`}}}>
        <ul css={{listStyle: `none`, padding: `0 0 1rem 1rem`, margin: `0`,  '@media (min-width: 1281px)': { paddingLeft: `0`} }}>
          {links}
        </ul>
      </div>
    </>
  )
}

const ContactSection = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div css={{
        borderTop: `2px solid #3498db`,
        borderBottom: isOpen ? `none` : `2px solid #3498db`,
        padding: `0.5rem 0 0.5rem 0.5rem`,
        '@media (min-width: 1281px)': {
          borderTop: `none`,
          borderBottom: `none`,
          padding: `0`,
        },
      }}>
        <div css={{ 
          display: `flex`, 
          justifyContent: 'space-between'
        }}>
          <SizedText as="h5" smallSize="1" largeSize="1.125">
            <Link to='/about/contact-us' css={{textDecoration: `none`, color: `#fff !important`}}>Contact Us</Link>
          </SizedText>
          <SizedText as="h5" smallSize="1" largeSize="1.125" styleOverrides={{ 
            transform: isOpen ? `rotate(45deg)` : `rotate(0deg)`,
            padding:`0 0.5rem`,
            '@media (min-width: 1281px)': {display: `none`}
            }} onClick={() => setIsOpen(!isOpen)}>
              <span>+</span>
          </SizedText>
        </div>
        <hr css={{width: `50px`, border: `1px solid #3498db`, display: `none`, '@media (min-width: 1281px)': { display: `inline-block`}}} />
      </div>
      <div css={{display: isOpen ? `block` : `none`, 
      borderBottom: isOpen ? `2px solid #3498db` : `none`,
      '@media (min-width: 1281px)': { display: `block`, borderBottom: `none`,}}}>
        <ul css={{
          listStyle: `none`, 
          padding: `0 0 1rem 1rem`, 
          margin: `0`,  
          '@media (min-width: 1281px)': { paddingLeft: `0`}, 
          'li span': {display: `inline-block`, verticalAlign: `top`, color: `#47597d`, fill: `#47597d`,},
          'li span:nth-of-type(2)': {paddingLeft: `1rem`, color: `#fff`}
        }}>
          {children}
        </ul>
      </div>
    </>
  )
}






//https://mdbootstrap.com/docs/b4/jquery/navigation/footer/

const Footer = ({data}) => { 
  return (
    <footer css={{backgroundColor: `#1c2331`, maxWidth: `100vw`, overflow: `hidden`,}}>

      {/* --- Social Links Sections --- */ }
      <Grid.FullWidthSection
        css={{
          backgroundColor: `#3498db`, 
          padding: `1.5rem 0`, 
          borderTop: `1px solid #6B7797`, 
          borderBottom: `1px solid #6B7797`}}
      > 
        <Grid.Block start="2" span="12">
          <div 
            css={{
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `center`,
              verticalAlign: `center`,
              textAlign: `center`,
              alignItems: `center`,
              '@media (min-width: 1281px)': {
                flexDirection: `row`,
                justifyContent: `space-between`,
                textAlign: `left`,
              }
              
            }}
          >
            <div>
              <SizedText as="h5" smallSize="1" largeSize="1.125" styleOverrides={{color: `#fff `, padding: `0`, margin: `0 0 1rem 0`, '@media (min-width: 1281px)': { margin: `0`}}}>
                Get connected with us on social networks!
              </SizedText>
            </div>
            <div css={{
              'a': {
                padding: `0.5rem`,
                marginLeft: `0.5rem`,
                fontSize: `1.424rem`,
                color: `#fff`
              }
            }}>
              <ExternalLink to="https://www.facebook.com/theclaxtonbank">
                <Icon icon={fbIcon} />
              </ExternalLink>
              <ExternalLink to="https://www.instagram.com/theclaxtonbank/">
                <Icon icon={igIcon} />
              </ExternalLink>
              <ExternalLink to="https://www.linkedin.com/company/theclaxtonbank">
                <Icon icon={liIcon} />
              </ExternalLink>
              {/* <ExternalLink to="https://www.facebook.com/theclaxtonbank">
                <Icon icon={ytIcon} />
              </ExternalLink> */}
              {/* <ExternalLink to="https://www.facebook.com/theclaxtonbank">
                <Icon icon={twIcon} />
              </ExternalLink> */}
            </div>
          </div>
        </Grid.Block>
      </Grid.FullWidthSection>

      {/* --- Menu Links Sections --- */ }
      <Grid.FullWidthSection css={{ padding: `3rem 0`}}>
        <Grid.Block start="2" span="12" >
            <div css={{
              display:  `grid`,
              color: `#fff !important`,
              gridTemplateColumns: `repeat(1, 1fr)`,
              '@media (min-width: 1281px)': {
                gridTemplateColumns: `repeat(5, 1fr)`,
                justifyContent: `space-between`,
                gridGap: `2rem`,
              },
              
            }}>
              <div>
                <MenuSection data={data.menusSitenavigationmenuYaml.menuItems[0]} />
              </div>
              <div>
                <MenuSection data={data.menusSitenavigationmenuYaml.menuItems[1]} />
              </div>
              <div>
                <MenuSection data={data.menusSitenavigationmenuYaml.menuItems[2]} />
              </div>
              <div>
                <MenuSection data={data.menusSitenavigationmenuYaml.menuItems[3]} />
                {/*<MenuSection data={data.menusSitenavigationmenuYaml.menuItems[4]} styles={{'@media (min-width: 1281px)':{marginTop: `4rem`, borderTop: `none`, padding: `0`}}} />*/}
              </div>
              <div>
                <ContactSection>
                  <li>
                    <span><Icon icon={locationIcon} /></span>
                    <span>PO Box 247 <br />121 West Main Street<br />Claxton, GA 30417</span>
                  </li>
                  <li>
                    <span><Icon icon={emailIcon} /></span>
                    <span>info@tcbga.bank</span>
                  </li>
                  <li>
                    <span><Icon icon={phoneIcon} /></span>
                    <span>(912) 739-3322</span>
                  </li>
                  <li>
                    <span><Icon icon={faxIcon} /></span>
                    <span>(912) 739-7456</span>
                  </li>
                </ContactSection>
                
                <div css={{display: `flex`, justifyContent: `center`, marginTop: `2rem`}}>
                          <PhExternalLink to="https://apps.apple.com/us/app/the-claxton-bank-mobile/id994317983?ign-mpt=uo%3D4">
                <StaticImage src="../images/products/digital-banking/app-store-badge.png" alt="Download on the App Store" height="50" width="168" />
              </PhExternalLink>
              <PhExternalLink to="https://play.google.com/store/apps/details?id=com.csiweb.digitalbanking.bk0704"><StaticImage src="../images/products/digital-banking/google-play-badge.png" alt="Get it on Google Play" height="50" width="168"/></PhExternalLink><br />
                </div>
                <div css={{textAlign: `center`, marginTop: `2rem`, color: `#47597d`}}>
                  <StaticImage src="../../../images/member-fdic-equal-housing-lender.png" alt="Download on the App Store"  width="128" /> <br />
                  <small>NMLS ID#: 433743</small>
                </div>
              </div>
            </div>
            
        </Grid.Block>
      </Grid.FullWidthSection>

      {/* --- Legal Links Sections --- */ }
      <Grid.FullWidthSection css={{borderTop: `1px solid rgba(0,0,0,0.4)`, backgroundColor: `rgba(0,0,0,0.2)`, paddingBottom: `2rem`,'@media (min-width: 1281px)': { paddingBottom: `0` }}}>
        <Grid.Block start="2" span="12" >
          <div css={{
            fontSize: `0.79rem`,
            display:  `flex`,
            flexDirection: `column`,
            color: `#fff`,
            textAlign: `center`,
            '@media (min-width: 1281px)': {
              flexDirection: `row`,
              justifyContent: `space-between`,
            }
          }}>
            <div>
              <p>Copyright &#169; 2022 - The Claxton Bank - All Rights Reserved</p>
            </div>
            <div css={{textAlign: `center`}}>
              <p css={{
                marginTop: `-0.5rem`,
                '@media (min-width: 1281px)': { marginTop: `0.79rem` },
                'a': {
                  display: `inline-block`,
                  margin: `0 0.5rem`,
                  textDecoration: `none`,
                  color: `#fff`,
                  ':hover': {textDecoration: `underline`}
                }
              }}>
                <a href="https://support.tcbga.com/">Support</a>|
                <Link to="/about/policies/terms-of-use">Terms <span css={{display: `none`, '@media (min-width: 1281px)': { display: `inline` },}}>& Conditions</span></Link>|
                <Link to="/about/policies/privacy-policy">Privacy Policy</Link>|
                <Link to="/about/policies/fdic-coverage">FDIC Coverage</Link>|
                <Link to="/about/policies/accessibility">Accessibility</Link>
              </p>
            </div>
          </div>
        </Grid.Block>
      </Grid.FullWidthSection>
      </footer>
  )
}

export default Footer